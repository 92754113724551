import { toast } from 'react-toastify';

class Notifier {
    toasterConfig = {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    }

    notify(type, message) {
        console.log('HELLO-')
        switch (type) {
            case 'success':
                toast.success(message, this.toasterConfig);
                break;
            case 'info':
                toast.info(message, this.toasterConfig);
                break;
            case 'error':
                toast.error(message, this.toasterConfig);
                break;
            case 'warning':
                toast.warning(message, this.toasterConfig);
                break;
            default:
                break;
        }
    }
}

export default new Notifier();