import React, { useState } from "react";

import Global from "../../infrastructure/Common/Global";

const WebContext = React.createContext({
    appTheme: '',
    switchTheme: () => { },

    appLang: '',
    switchLang: () => { },

    isLoggedIn: false,
    changeLoginStatus: () => { },

    loginUserName: '',
    setLoginUserName: () => { },

    appSettings: {},
    setAppSettings: () => { },

    logout: () => { },
});

export const WebContextProvider = (props) => {
    // const getBrowserTheme = () => {
    //     return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark-theme' : 'light-theme';
    // };

    const initialTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'dark-theme';
    const [theme, setTheme] = useState(initialTheme);

    const initialLang = localStorage.getItem('language') ? localStorage.getItem('language') : 'bg';
    const [language, setLanguage] = useState(initialLang);

    const [settings, setSettings] = useState({});

    const siteUrls = {
        login: Global.login,
    }

    const themeHandler = (th) => {
        setTheme(th);
        localStorage.setItem('theme', th);
    };

    const languageHandler = (lng) => {
        setLanguage(lng);
        localStorage.setItem('language', lng);
    };

    const isValid = () => {
        const token = localStorage.getItem('auth_token');
        // return true;
        if (token) {
            Global.httpOptions = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            };
            const payload = token.split('.')[1];
            const payloadParts = JSON.parse(atob(payload));
            // const payloadParts = JSON.parse(Buffer.from(payload, 'base64'));
            // console.log(payloadParts);
            if (payloadParts) {
                return Object.values(siteUrls).indexOf(payloadParts.iss) > -1 ? true : false;
            }
            // return true;
        } else {
            Global.httpOptions = {};
            return false;
        }

        // setIsUserLogin(st);
        // localStorage.setItem('theme', st);
    };

    const initialUserName = localStorage.getItem('username');
    const [isUserLogin, setIsUserLogin] = useState(isValid);
    const [userName, setUserName] = useState(initialUserName);

    const changeAuthStatus = (st) => {
        console.log('CHANGE-State', st);
        setIsUserLogin(isValid);
    };

    const handleLogout = () => {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('username');
        // localStorage.removeItem('user');
        setIsUserLogin(isValid);
    }

    const contextValue = {
        appTheme: theme,
        switchTheme: themeHandler,

        appLang: language,
        switchLang: languageHandler,

        isLoggedIn: isUserLogin,
        changeLoginStatus: changeAuthStatus,

        loginUserName: userName,
        setLoginUserName: setUserName,

        appSettings: settings,
        setAppSettings: setSettings,

        logout: handleLogout
    }

    return <WebContext.Provider value={contextValue}>{props.children}</WebContext.Provider>
};

export default WebContext;