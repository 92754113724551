import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import WebContext from '../../../../application/contexts/WebContext';
import Global from '../../../../infrastructure/Common/Global';

import ClientApis from '../../APIs/ClientApis';
import BlogCard from '../../Shared/BlogCard';

const LandingBlogs = () => {
    const { t } = useTranslation();
    const webCtx = useContext(WebContext);

    const [isLoading, setIsLoading] = useState(true);
    const [posts, setPosts] = useState([]);

    const baseURL = Global.baseUrl + "/";

    useEffect(() => {
        getPosts();
    }, []);

    const getPosts = () => {
        ClientApis.blog('home=1')
            .then(res => {
                // setTimeout(() => {
                setPosts(res.data?.result?.posts);
                setIsLoading(false);
                // }, 2000);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    };

    return (
        <section>
            <div className="container-xxl my-5 py-5">
                <div className="section-header">
                    <div className="primary">{t('our_blog_message')}</div>
                    <h2 className="title fw-medium mb-0">{t('our_blog_title')}</h2>
                    <div>{t('our_blog_desc')}</div>
                </div>

                <div className="section-content mt-5">
                    <div className="row">
                        {posts.length && posts.map((item, index) => (

                            <div className="col-md-6 mb-3" key={index}>
                                <BlogCard data={item} appLang={webCtx.appLang} baseURL={baseURL} />
                            </div>

                        ))}

                        <div className="text-end">
                            <Link to={'/blog-n-news'} className="dim-text pointer">
                                {t('view_all')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LandingBlogs;