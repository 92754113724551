import React, { useEffect, useState } from 'react';

import Featured from './Featured';
import LandingCourses from './LandingCourses';
import LandingBlogs from './LandingBlogs';
import LandingPartners from './LandingPartners';
import LandingContact from './LandingContact';
import LandingServices from './LandingServices';
import Banners from '../Shared/Banners';

import './landing.scss';
import ClientApis from '../APIs/ClientApis';

const Landing = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [featuredSliderData, setFeaturedSliderData] = useState([]);

    useEffect(() => {
        getPost();
    }, []);

    const getPost = () => {
        // const cmsQueryBulk = [
        //     {
        //         slug: 'featured-section-slider',
        //         post_type: 'cms'
        //     }
        // ];

        const query = {
            slug: 'featured-section-slider',
            post_type: 'cms'
        };

        setIsLoading(true);
        // ClientApis.cmsPostBulk(cmsQueryBulk)
        ClientApis.cmsPost(query)
            .then(res => {
                // setParentPost(res.data.result.detail);
                if (res.data?.result) {
                    // setTimeout(() => {
                    setIsLoading(false);
                    setFeaturedSliderData(res.data.result);
                    // }, 3000);
                }
            })
            .catch(err => {
                setIsLoading(false);
            })
    };

    return (
        <React.Fragment>
            <Featured posts={featuredSliderData.posts} featured={featuredSliderData.detail} isWaiting={isLoading} />
            <Banners />
            <LandingCourses />
            <hr />
            <LandingBlogs />
            <hr />
            <LandingPartners />
            <hr />
            <LandingContact />
        </React.Fragment>
    );
};

export default Landing;