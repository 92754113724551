import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from "react-i18next";

import ImageSlider from '../../Shared/ImageSlider/ImageSlider';
import Global from '../../../../infrastructure/Common/Global';
import WebContext from '../../../../application/contexts/WebContext';

import { ReactComponent as AssetLoader } from "./../../../../assets/images/loader.svg";

import './featured.scss';

const Featured = ({ isWaiting, featured, posts }) => {
    const { t } = useTranslation();
    const webCtx = useContext(WebContext);

    const baseURL = Global.baseUrl + "/";
    const [slides, setSlides] = useState([]);

    useEffect(() => {
        if (posts) {
            const formattedSlides = posts.map(post => ({
                image: baseURL + post.featured_url,
                title_en: post.title_en,
                title_bg: post.title_bg,
                content_en: post.content_en,
                content_bg: post.content_bg
            }));
            setSlides(formattedSlides);
        }
    }, [posts]);

    return (
        <section>
            <div className="container-fluid p-0">
                <div className="featured-content">
                    {isWaiting ?
                        <div className="lazy-loader">
                            <AssetLoader />
                        </div>
                        :
                        <React.Fragment>
                            {slides.length > 0 ?
                                <ImageSlider slides={slides} />
                                :
                                <div className="featured-section">
                                    <div className="featured-hero-data">
                                        <div className="fh-bg" style={{ backgroundImage: `url('${baseURL + featured?.featured_url}')` }}>
                                            <div className="featured-content-container h-100">

                                                <div className="container-xxl h-100 px-4 px-md-2">
                                                    <div className="featured-hero-content">
                                                        <div className="title h3">{featured ? featured[`title_${webCtx.appLang}`] : t('app_name')}</div>
                                                        <div className="desc">
                                                            {/* {featured[`content_${webCtx.appLang}`] || t('nodis_slogan')} */}
                                                            {featured ? featured[`content_${webCtx.appLang}`] : t('nodis_slogan')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        </section>
    );
};

export default Featured;