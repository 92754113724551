import React from 'react';

import { useTranslation } from "react-i18next";

import ContactForm from '../../Shared/ContactForm';

const LandingContact = () => {
    const { t } = useTranslation();

    return (
        <section>
            <div className="container-xxl my-5 py-5">
                <div className="section-header" data-aos="fade-up" data-aos-duration="500" data-aos-easing="linear">
                    <div className="primary">{t('contact_us_message')}</div>
                    <h2 className="title fw-medium mb-0">{t('contact_us_title')}</h2>
                    <div>{t('contact_us_desc')}</div>
                </div>

                <div className="section-content mt-5">
                    <ContactForm />
                </div>
            </div>
        </section>
    );
};

export default LandingContact;