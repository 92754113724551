const i18n = {
    app_name: 'Nodis',
    menu_partners: 'Партньори',
    menu_library: 'библиотека',
    menu_about_us: 'За нас',
    menu_our_team: 'Нашия екип',
    menu_contact_us: 'Свържете се с нас',
    menu_courses: 'Курсове',
    menu_home: 'У дома',
    menu_blogs_n_news: 'Блогове / Новини',
    menu_banners_n_ads: 'Банери / Реклами',
    settings: 'Настройки',

    community: 'Общност',
    powered_by: 'Проектиран и разработен от',
    navigate_to: 'Навигирайте до',
    address: 'Адрес',
    mail: 'поща',
    electronic_mail: 'Електронна поща',
    support: 'поддържа',
    phone_support: 'Поддръжка по телефона',
    name: 'Име',
    email: 'Електронна поща',
    subject: 'Предмет',
    write_content: 'Напишете вашето съдържание тук',
    submit: 'Изпратете',

    nodis_slogan: 'Професионално обучение по транспорт',
    welcome_back: 'Добре дошъл обратно',
    login_account: 'Вход в акаунт',
    email_id: 'Имейл ID',
    // forget_password : 'Забравена парола?',
    login: 'Влизам',
    loading: 'Зареждане',
    saving: 'Спестяване',
    sending: 'Изпращане',
    send: 'Изпратете',
    all_fields_are_required: 'Всички полета са задължителни',
    add_course: 'Добавете нов курс',
    update_course: 'Актуализирайте курса',
    cms: 'Съдържание',
    content: 'Съдържание',
    cms_desc: 'Система за управление на съдържанието',
    cms_posts: 'Публикации',
    general: 'Общ',
    footer: 'Долен колонтитул',
    settings_desc: 'Персонализирайте предпочитанията и настройките',
    enable_edit: 'Активирайте Редактиране',
    save_changes: 'Запазите промените',
    cancel: 'Отказ',
    contact_number: 'Номер за връзка',
    contact_email: 'Имейл за връзка',
    featured_text_en: 'Представен текст (английски)',
    featured_text_bg: 'Представен текст (български)',
    copyright_text_en: 'Текст за авторски права (английски)',
    copyright_text_bg: 'Авторски текст (български)',
    powered_by_name: 'Осъществено от (име)',
    powered_by_link: 'Осъществено от (връзка)',
    add_new_post: 'Добавете публикация',
    edit_post: 'Редактиране',
    view_post: 'Вижте публикацията',
    filter: 'Филтър',
    data_not_found: 'Данните не са намерени',
    title: 'Заглавие',
    label: 'Етикет',
    status: 'Статус',
    actions: 'Действия',
    data_order: 'Ред на данни',
    open: 'Отворете',
    view: 'Преглед',
    edit: 'редактиране',
    delete: 'Изтрий',
    english: 'Английски',
    bulgarian: 'български',
    your_post_title: 'Вашето заглавие на публикацията',
    post_content: 'Post Публикувайте съдържание',
    media: 'Медия',
    upload_new: 'Качване Ново',
    primary_post: 'Основна публикация',
    tatus_publish: 'Публикувано',
    status_pending: 'Чернова',
    save_n_publish: 'Запазване и публикуване',
    deactivate_post: 'Деактивирайте',
    publish_post: 'Публикувай',
    publish_course: 'Публикувай',
    deactivate_course: 'Деактивирайте',
    publish: 'Публикувай',
    pending: 'Чернова',

    add_new_course: 'Добавяне на нов курс',
    edit_course: 'Редактиране на курса',
    view_course: 'Преглед на курса',
    your_course_title: 'Вашето заглавие на курса',
    short_desc: 'Кратко описание',
    write_short_desc: 'Напишете кратко описание',
    write_about_the_course: 'Пишете за курса',
    add_new_feature: 'Добавете нова функция',
    add_new_skill: 'Добавете ново умение',
    select_language: 'Избери език',

    broad_selection_of_courses: 'Богат избор от курсове',
    broad_selection_of_courses_desc: 'Разгледайте курсовете, които предлагаме и направете своя избор. Ние ви гарантираме актуални програми, модерно съдържание и сертифицирани преподаватели.',
    taught_in_langs: 'Преподава се на езици',
    instructor: 'Инструктор(и)',
    what_you_will_learn: 'Какво ще научите',
    skills_you_will_gain: 'Умения, които ще придобиете',
    about: 'относно',
    contact_us_message: 'Ние се интересуваме от Вашето мнение',
    contact_us_title: 'Свържете се с Нас',
    contact_us_desc: 'Ще дадем отговор на всеки въпрос и ще Ви предложим най-доброто решение.',
    popular_courses_message: 'Професионално oбучение',
    popular_courses_title: 'Популярни Курсове',
    popular_courses_desc: 'Разгледайте квалификационните курсове, водени от нашите сертифицирани експерти.',
    our_best_brightest: 'Нашите най-добри и най-ярки',
    blog_n_news: 'Блог и новини',
    manage_blog_n_news: 'Управление на блог и новини',
    author: 'Автор',
    category: 'Категория',
    select_category: 'Избери категория',
    blog: 'Блог',
    news: 'Новини',
    partners: 'Партньори',
    manage_partners: 'Управление на партньори',
    add_new_partner: 'Добавяне на партньор',
    view_partner: 'Вижте подробности за партньора',
    edit_partner: 'Редактиране на подробности за партньора',

    company_name: 'Име на фирмата',
    company_info: 'Информация за компанията',
    write_few_lines_about_company: 'Напишете няколко реда за компанията',
    ceo: 'изпълнителен директор',
    contact_person_name: 'Лице за контакт',
    contact_info: 'Информация за контакт',
    partners_message: 'Регионално представителство',
    partners_title: 'Нашите Партньори',
    partners_desc: 'Запознайте се със стратегическите ни партньори, чрез които нашите услуги достигат до вас.',
    view_all: 'Виж всички',
    view_detail: 'Виж детайл',
    banners_n_ads: 'Банери/реклами',
    manage_banners_n_ads: 'Управление на банери/реклами',
    add_new_banner: 'Добавете банер/реклама',
    view_banner: 'Вижте подробности за банер/реклама',
    edit_banner: 'Редактиране на подробности за банер/реклама',
    linking: 'Свързване',
    url: 'URL или препратка',

    our_blog_message: 'Актуална информация',
    our_blog_title: 'Нашият Блог',
    our_blog_desc: 'Запознайте се с нашите новини, анализи и коментари, свързани с автомобилните превози и логистиката.',
    blog_articles_title: 'Разгледайте нашия информативен блог',
    blog_articles_desc: 'Достъп до изобилие от информация в нашите обширни блогове и статии.',
    posted: 'Публикувано',
    by: 'от'
}

export default i18n;