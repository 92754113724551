import React, { useContext, useEffect, useState } from 'react';
import WebContext from '../../../../application/contexts/WebContext';
import { useTranslation } from 'react-i18next';
import ClientApis from '../../APIs/ClientApis';
import Notifier from '../../../../application/utils/Notifier';

const ContactForm = () => {
    const webCtx = useContext(WebContext);
    const { t } = useTranslation();

    const dataStructure = { name: "", email: "", subject: "", message: "" }
    const [contactData, setContactData] = useState({ ...dataStructure });
    const [isSending, setIsSending] = useState(false);
    const [validation, setValidation] = useState(false);


    useEffect(() => {
        if (!webCtx.appSettings) {
            getSettings();
        }
    }, []);

    const getSettings = () => {
        ClientApis.feSettings()
            .then(res => {
                webCtx.setAppSettings(res.data.result);
            })
            .catch(err => {
                console.log(err);
            })
    };

    const handleChange = (e, item = null, itemIndex = null, tIndex = null) => {
        const { name, value, checked } = e.target;
        setContactData({ ...contactData, [name]: value });
    }

    const sendMessage = (e) => {
        e.preventDefault();
        setIsSending(true);

        if (!contactData.name || !contactData.email || !contactData.subject || !contactData.message) {
            setIsSending(false);
            setValidation(true);
            // You can display an error message or handle it as you prefer.
            // alert('All fields are required');
            return;
        } else {
            setValidation(false);
        }

        console.log('contact-us-data', contactData);
        // let formData = {};

        // setIsSaving(true);
        ClientApis.contactUs(contactData)
            .then(res => {
                setIsSending(false);
                Notifier.notify('success', "Message sent successfully, We'll contact back soon");
                setContactData(dataStructure);
                // localStorage.setItem('login_user', JSON.stringify(res.data.user));
                // webCtx.handleUserInfo();
            })
            .catch(err => {
                setIsSending(false);
                // console.log('ERR...', err.response);
                // if (err.response.data.message) {
                //     NotifierHelper.notify('error', err.response.data.message);
                // } else {
                //     NotifierHelper.notify('error', "Something went wrong, please try again");
                // }
            });

    }

    return (
        <section>
            <div className="row">
                <div className="col-md-4 home-contact-content me-auto">
                    <div className="mb-4" data-aos="fade-up" data-aos-duration="500" data-aos-easing="linear">
                        <div className="title fw-medium">{t('address')}</div>
                        <div>{webCtx.appSettings?.address}</div>
                    </div>

                    <hr data-aos="fade-up" data-aos-duration="1000" />

                    <div className="my-4" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="linear">
                        <div className="title fw-medium">{t('electronic_mail')}</div>
                        <div>{webCtx.appSettings?.contact_email}</div>
                    </div>

                    <hr data-aos="fade-up" data-aos-duration="1500" />

                    <div className="my-4" data-aos="fade-up" data-aos-duration="1500" data-aos-easing="linear">
                        <div className="title fw-medium">{t('phone_support')}</div>
                        <div>{webCtx.appSettings?.contact_number}</div>
                    </div>

                </div>
                <div className="col-md-6">
                    <div data-aos="fade-up" data-aos-duration="2000">
                        <form onSubmit={sendMessage}>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <input
                                        type="text"
                                        className="form-control nodis-field"
                                        placeholder={t('name')}
                                        name="name"
                                        value={contactData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <input
                                        type="email"
                                        className="form-control nodis-field"
                                        placeholder={t('email')}
                                        name="email"
                                        value={contactData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <input
                                        type="text"
                                        className="form-control nodis-field"
                                        placeholder={t('subject')}
                                        name="subject"
                                        value={contactData.subject}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <textarea
                                        className="form-control nodis-field"
                                        placeholder={t('write_content')}
                                        rows={6}
                                        name="message"
                                        value={contactData.message}
                                        onChange={handleChange}
                                        required
                                    ></textarea>
                                </div>
                                {validation &&
                                    <div className="col-12 danger">* {t('all_fields_are_required')}</div>
                                }
                                <div className="col-md-12 text-end">

                                    <button
                                        type="submit"
                                        className="btn nodis-btn outline btn-block w-150"
                                        disabled={isSending}
                                    >
                                        {isSending &&
                                            <div className="button-loader">
                                                {t('sending')}...
                                            </div>
                                        }
                                        {t('send')}
                                    </button>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactForm;