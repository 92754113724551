const i18n = {
    app_name: 'Nodis',
    menu_partners: 'Partners',
    menu_library: 'Library',
    menu_about_us: 'About Us',
    menu_our_team: 'Our Team',
    menu_contact_us: 'Contact Us',
    menu_courses: 'Courses',
    menu_home: 'Home',
    menu_blogs_n_news: 'Blogs / News',
    menu_banners_n_ads: 'Banners / Ads',
    settings: 'Settings',

    community: 'Community',
    powered_by: 'Designed & Developed by',
    navigate_to: 'Navigate to',
    address: 'Address',
    mail: 'Mail',
    electronic_mail: 'Electronic mail',
    support: 'Support',
    phone_support: 'Phone support',
    name: 'Name',
    email: 'Email',
    subject: 'Subject',
    write_content: 'Write your content here',
    submit: 'Submit',

    nodis_slogan : 'Vocational Training in Transport',
    welcome_back : 'Welcome Back',
    login_account : 'Login Account',
    email_id : 'Email ID',
    // forget_password : 'Forget Password?',
    login : 'Login',
    loading: 'Loading',
    saving: 'Saving',
    sending: 'Sending',
    send: 'Send',
    all_fields_are_required: 'All fields are required',
    add_course: 'Add new course',
    update_course: 'Update course',
    cms: 'Content Management',
    content: 'CMS',
    cms_desc: 'Content Management System',
    cms_posts: 'Posts',
    general: 'General',
    footer: 'Footer',
    settings_desc: 'Customize preferences and settings',
    enable_edit: 'Enable Edit',
    save_changes: 'Save Changes',
    cancel: 'Cancel',
    contact_number: 'Contact Number',
    contact_email: 'Contact Email',
    featured_text_en: 'Featured Text (English)',
    featured_text_bg: 'Featured Text (Bulgarian)',
    copyright_text_en: 'Copyright Text (English)',
    copyright_text_bg: 'Copyright Text (Bulgarian)',
    powered_by_name: 'Powered by (Name)',
    powered_by_link: 'Powered by (Link)',
    add_new_post: 'Add New Post',
    edit_post: 'Edit Post',
    view_post: 'View Post',
    filter: 'Filter',
    data_not_found: 'Data Not Found',
    title: 'Title',
    label: 'Label',
    status: 'Status',
    actions: 'Actions',
    data_order: 'Data Order',
    open: 'Open',
    view: 'View',
    edit: 'Edit',
    delete: 'Delete',
    english: 'English',
    bulgarian: 'Bulgarian',
    your_post_title: 'Your Post Title',
    post_content: 'Post Description',
    media: 'Media',
    upload_new: 'Upload New',
    primary_post: 'Primary Post',
    status_publish: 'Published',
    status_pending: 'Draft',
    save_n_publish: 'Save & Publish',
    deactivate_post: 'Deactivate Post',
    publish_post: 'Publish Post',
    publish_course: 'Publish Course',
    deactivate_course: 'Deactivate Course',
    publish: 'Publish',
    pending: 'Draft',
    
    add_new_course: 'Add New Course',
    edit_course: 'Edit Course',
    view_course: 'View Course',
    your_course_title: 'Your Course Title',
    short_desc: 'Short Description',
    write_short_desc: 'Write Short Description',
    write_about_the_course: 'Write about the course',
    add_new_feature: 'Add new feature',
    add_new_skill: 'Add new skill',
    select_language: 'Select language',

    broad_selection_of_courses: 'A broad selection of courses',
    broad_selection_of_courses_desc: 'Choose from over 100 online video courses with new additions published every month.',
    taught_in_langs: 'Taught in languages',
    instructor: 'Instructor(s)',
    what_you_will_learn: 'What you\'ll learn',
    skills_you_will_gain: 'Skills you\'ll gain',
    about: 'About',
    contact_us_message: 'We are interested in your opinion',
    contact_us_title: 'Contact Us',
    contact_us_desc: 'We will answer all your questions and offer you the best solution.',
    popular_courses_message: 'Vocational training',
    popular_courses_title: 'Popular Courses',
    popular_courses_desc: 'Check out the qualification courses led by our certified experts.',
    our_best_brightest: 'Our Best & Brightest',
    blog_n_news: 'Blog & News',
    manage_blog_n_news: 'Manage Blog & News',
    add_blog_post: 'Add New Blog',
    author: 'Author',
    category: 'Category',
    select_category: 'Select category',
    blog: 'Blog',
    news: 'News',
    partners: 'Partners',
    manage_partners: 'Manage Partners',
    add_new_partner: 'Add New Partner',
    view_partner: 'View Partner Details',
    edit_partner: 'Edit Partner Details',
    company_name: 'Company Name',
    company_info: 'Company Info',
    write_few_lines_about_company: 'Write few lines about company',
    ceo: 'CEO',
    contact_person_name: 'Contact Person',
    contact_info: 'Contact Info',
    partners_message: 'Regional representation',
    partners_title: 'Our Partners',
    partners_desc: 'Meet our strategic partners through which our services reach you.',
    view_all: 'View all',
    view_detail: 'View detail',
    banners_n_ads: 'Banners/Ads',
    manage_banners_n_ads: 'Banner/Ads Management',
    add_new_banner: 'Add New Banner/Ad',
    view_banner: 'View Banner/Ad Details',
    edit_banner: 'Edit Banner/Ad Details',
    linking: 'Linking',
    url: 'URL or Reference',

    our_blog_message: 'Latest news',
    our_blog_title: 'Our Blogs',
    our_blog_desc: 'Check out our news, analysis and commentary related to road transport and logistics.',
    blog_articles_title: 'Explore our Informative Blog',
    blog_articles_desc: 'Access a wealth of information in our extensive blogs & Articles.',
    posted: 'Posted',
    by: 'By'
}

export default i18n;