import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import WebContext from "../../../../application/contexts/WebContext";
import Global from "../../../../infrastructure/Common/Global";

import ClientApis from "../../APIs/ClientApis";

import "swiper/css";
import "swiper/css/pagination";

import "./banners.scss";

const Banners = () => {
  const { t } = useTranslation();
  const webCtx = useContext(WebContext);

  const [isLoading, setIsLoading] = useState(true);
  const [banners, setBanners] = useState([]);

  const baseURL = Global.baseUrl + "/";

  useEffect(() => {
    getBanners();
  }, []);

  const getBanners = () => {
    ClientApis.banners()
      .then((res) => {
        // setTimeout(() => {

        if (res.data?.result?.posts) {
          const formattedSlides = res.data.result.posts.map((post) => ({
            image: baseURL + post.featured_url,
            title_en: post.title_en,
            title_bg: post.title_bg,
            content_en: post.content_en,
            content_bg: post.content_bg,
            url: post.url,
          }));
          setBanners(formattedSlides);
        }

        setIsLoading(false);
        // }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <div className="container-xxl my-5 pt-5">
      <Swiper
        slidesPerView={3}
        spaceBetween={20}
        autoplay={{
          delay: 3000,
          // disableOnInteraction: false,
        }}
        loop={true}
        modules={[Autoplay]}
        className="banner-slider"
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
      >
        {banners.map((slide, index) => (
          <SwiperSlide key={index}>
            <a
              className="banner-slide"
              href={
                slide.url
                  ? slide.url.startsWith("http")
                    ? slide.url
                    : `https://${slide.url}`
                  : undefined
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={slide.image} alt={"slider-" + (index + 1)} />
              <div className="slide-title">
                {slide[`title_${webCtx.appLang}`]}
              </div>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Banners;
