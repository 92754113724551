import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import * as moment from 'moment';

import './blog-card.scss';

const BlogCard = ({ data, appLang, baseURL }) => {
    const { t } = useTranslation();

    return (
        <div className="blog-card p-2">
            <div className="row g-0">
                <div className="col-md-8 content p-3">
                    <div className="mb-2 d-flex justify-content-between align-items-center">
                        <div className="nodis-label">{t(data.sub_type || 'blog')}</div>
                        <div className="small dim-text">{data.created_at ? moment(data.created_at).format('DD-MMM-YYYY') : ''}</div>
                    </div>
                    <Link to={`/blog-n-news/article/${data.id}`} className="title fw-medium">{data['title_' + appLang]}</Link>
                    <div className="blog-text mt-3">
                        {/* {data['content_' + appLang]} */}
                        <div className="render-content" dangerouslySetInnerHTML={{ __html: data[`content_${appLang}`] }} />
                    </div>

                    <div className="dim-text mt-auto">{t('by')}: {data.author}</div>

                </div>
                <div className="col-md-4 poster-container" style={{ backgroundImage: `url('${baseURL + data.featured_url}')` }}></div>
            </div>
        </div>
    );
};

export default BlogCard;